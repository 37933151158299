const dev = {
    application: {
        environment: "dev",
        baseUrl: "https://localhost:44368",
        apiUrl: "https://localhost:44368"
    }
};
const qa = {
    application: {
        environment: "qa",
        baseUrl: "https://entrystreamingupdate-qa.latingrammy.com",
        apiUrl: "https://app-entrystreamingupdate-lra-backend-qa.azurewebsites.net"
    }
};
  
const prod = {
    application: {
        environment: "prod",
        baseUrl: "https://entrystreamingupdate.latingrammy.com",
        apiUrl: "https://app-entrystreamingupdate-lra-backend.azurewebsites.net"
    }
};

//This "REACT_APP_STAGE" variable is set in the package.json -> scripts -> start command and is only set for DEV.
//When building the application for QA, there is a build-qa command. 
//Production is never set and therefor when it is not DEV or QA, it will default to PROD.
//This file needs to be imported and can be accessed like "config.environment"
const environment = (process.env.REACT_APP_STAGE === 'dev') ? dev : ((process.env.REACT_APP_STAGE === 'qa') ? qa : prod);

export default {
// Add common config values here
    environment
};