import React, { Component } from 'react';
import config from './config';

class LarasHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageNumber: 1,
            apiServiceError: "",
            streamingSubmissionCompleted: false,
            academyID: 2,
            languageID: 2,
            id: "",
            userID: "",
            entryID: "",
            artistName: "",
            title: "",
            categoryName: "",
            categoryNumber: "",
            amazon: "",
            amazonOriginal: "",
            apple: "",
            appleOriginal: "",
            tidal: "",
            tidalOriginal: "",
            spotify: "",
            spotifyOriginal: "",
            deezer: "",
            bandcamp: "",
            youTube: "",
            vimeo: "",
            other: "",
            translations: [],
            resultsArray: [],


            txtWelcome: 1,
            txtUserID: 2,
            txtUserIDRequired: 3,
            txtEntryID: 4,
            txtEntryIDIDRequired: 5,
            txtRetrieveEntry: 6,
            txtEntryNotFound: 7,
            txtNotValidURL: 8,
            txtEntryUpdated: 9,
            txtCompleteSubmission: 10,
            txtReviewAnotherEntry: 11,
            txtEntryIDNotFound: 12,
            txtNoMatchingRecordFound: 13,
            txtErrorOccurred: 14,
            txtSelectEntryToUpdate: 15,
            txtSelect: 16
        }
    }

    componentDidMount() {
        this.loadTranslations();

        this.setState({
            isLoading: false
        })
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    async loadTranslations() {
        let url = config.environment.application.apiUrl + '/api/entry/translations/' + this.state.languageID;

        return await fetch (url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (config.environment.application.environment !== "prod")
                console.log(data)

            this.setState({translations: data});

            return data;
        }).catch((error) => {
            console.log(error);
        })
    }
    isUserIDValid() {
        return (this.state.userID !== "");
    }

    isEntryIDValid() {
        return (this.state.entryID !== "");
    }

    isVideoCategory() {
        return (this.state.categoryNumber === "52");
    }

    isRemixCategory() {
        return false;
    }

    retrieveEntryForm = (event) => {
        event.preventDefault();
        this.setState({isLoading: true});

        this.retrieveEntry().then((response) => {
            this.setState({isLoading: false});
        

            if (response !== true) {
                this.setState({ apiServiceError: this.getTranslationString(this.state.txtEntryNotFound) });
            }
            else {
                if (this.state.resultsArray.length > 1)
                    this.setState({ pageNumber: 2 });
                else
                    this.setState({ pageNumber: 3 });
            }
        });
    }

    async retrieveEntry() {
        this.setState({
            apiServiceError: ""
        });

        let url = config.environment.application.apiUrl + "/api/entry/retrieveEntry";

        if (config.environment.application.environment !== "prod")
            console.log(url);

        let user_and_entry = {
            userID: this.state.userID,
            entryID: this.state.entryID,
            academyID: this.state.academyID,
            languageID: this.state.languageID
        };

        if (config.environment.application.environment !== "prod")
            console.log(user_and_entry);

        let return_value = await fetch(url, {
            method: 'POST',
            headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            }),
            body: JSON.stringify(user_and_entry)
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (config.environment.application.environment !== "prod")
                console.log(data);

            if (Array.isArray(data))
            {
                if (data.length === 1) {
                    let current_data = data[0];

                    this.setState({ resultsArray: []});
                    this.setStateForEntry(current_data);
                    /*
                    this.setState({
                        apiServiceError: current_data.errorString,
                        categoryName: current_data.categoryName,
                        categoryNumber: current_data.categoryNumber,
                        artistName: current_data.artistName,
                        title: current_data.title,
                        amazon: current_data.amazon,
                        amazonOriginal: current_data.amazonOriginal,
                        apple: current_data.apple,
                        appleOriginal: current_data.appleOriginal,
                        tidal: current_data.tidal,
                        tidalOriginal: current_data.tidalOriginal,
                        spotify: current_data.spotify,
                        spotifyOriginal: current_data.spotifyOriginal,
                        deezer: current_data.deezer,
                        bandcamp: current_data.bandcamp,
                        youTube: current_data.youTube,
                        vimeo: current_data.vimeo,
                        other: current_data.other,
                        isVideo: current_data.isVideo,
                        isRemix: current_data.isRemix,
                        resultsArray: []
                    });
                    */
                }
                else {
                    this.setState({
                        resultsArray: data,
                        categoryName: data[0].categoryName,
                        categoryNumber: data[0].categoryNumber,
                        userID: data[0].userID,
                        entryID: data[0].entryID
                    });
                }
                    
    
                return true;
            }
            else
                return false;
        })
        .catch((error) => {
            console.log("In Error : " + error);
            return false;
        });

        return return_value;
    }

    async setStateForEntry(current_data) {
        this.setState({
            apiServiceError: current_data.errorString,
            id: current_data.id,
            categoryName: current_data.categoryName,
            categoryNumber: current_data.categoryNumber,
            artistName: current_data.artistName,
            title: current_data.title,
            amazon: current_data.amazon,
            amazonOriginal: current_data.amazonOriginal,
            apple: current_data.apple,
            appleOriginal: current_data.appleOriginal,
            tidal: current_data.tidal,
            tidalOriginal: current_data.tidalOriginal,
            spotify: current_data.spotify,
            spotifyOriginal: current_data.spotifyOriginal,
            deezer: current_data.deezer,
            bandcamp: current_data.bandcamp,
            youTube: current_data.youTube,
            vimeo: current_data.vimeo,
            other: current_data.other,
            isVideo: current_data.isVideo,
            isRemix: current_data.isRemix
        });
    }

    selectEntry = async (current_entry) => {
        await this.setStateForEntry(current_entry.current_result).then(this.setState({pageNumber: 3}));
    }

    isValidURLForSource(url_string, source_string)
    {
        if (url_string === undefined || url_string === null || url_string.length === 0)
            return true;

        if (!this.isUrl(url_string))
            return false;

        url_string = url_string.toLowerCase();
        source_string = source_string.toLowerCase();

        if (source_string === "youtube")
            return (url_string.indexOf(source_string + ".com") !== -1 || url_string.indexOf("youtu.be") !== -1);
        else
            return (url_string.indexOf(source_string + ".com") !== -1);
    }

    isUrl(url_string)
    {
        let regexp = /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/;
        return regexp.test(url_string);
    }

    restartApplication = (event) => {
        this.setState({
            pageNumber: 1,
            apiServiceError: "",
            streamingSubmissionCompleted: false,
            entryID: "",
            userID: ""
        })
    }

    getTranslation(translation_id, streaming_media_type) {
        for (var x = 0; x < this.state.translations.length; x++) {
            if (this.state.translations[x].translationID === translation_id) {
                if (streaming_media_type === undefined)
                    return {__html: this.state.translations[x].description};
                else
                    return {__html: this.state.translations[x].description.replace("{0}", streaming_media_type)};
            }
                
        }

        return "";
    }

    getTranslationString(translation_id) {
        for (var x = 0; x < this.state.translations.length; x++) {
            if (this.state.translations[x].translationID === translation_id)
                return this.state.translations[x].description;
        }

        return "";
    }

    changeLanguage(new_language) {
        this.setState({languageID: new_language}, () => {
            this.loadTranslations();
        })
    }

    saveEntryForm = (event) => {
        event.preventDefault();

        this.saveEntry().then((response) => {
            if (response === true) {
                this.setState({ streamingSubmissionCompleted: true });
            }
        });
    }

    async saveEntry() {
        this.setState({
            isLoading: true,
            apiServiceError: "",
            streamingSubmissionCompleted: false
        });

        let url = config.environment.application.apiUrl + "/api/entry/saveEntry";

        if (config.environment.application.environment !== "prod")
            console.log(url);

        let user_and_entry = {
            id: this.state.id,
            academyID: this.state.academyID,
            userID: this.state.userID,
            entryID: this.state.entryID,
            artistName: this.state.artistName,
            title: this.state.title,
            categoryName: this.state.categoryName,
            categoryNumber: this.state.categoryNumber,
            amazon: this.state.amazon,
            amazonOriginal: this.state.amazonOriginal,
            apple: this.state.apple,
            appleOriginal: this.state.appleOriginal,
            tidal: this.state.tidal,
            tidalOriginal: this.state.tidalOriginal,
            spotify: this.state.spotify,
            spotifyOriginal: this.state.spotifyOriginal,
            deezer: this.state.deezer,
            bandcamp: this.state.bandcamp,
            youTube: this.state.youTube,
            vimeo: this.state.vimeo,
            other: this.state.other
        };

        if (config.environment.application.environment !== "prod")
            console.log(user_and_entry);

        let return_value = await fetch(url, {
            method: 'POST',
            headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            }),
            body: JSON.stringify(user_and_entry)
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (config.environment.application.environment !== "prod")
                console.log(data);

            this.setState({
                id: data.id,
                apiServiceError: data.errorString,
                categoryName: data.categoryName,
                categoryNumber: data.categoryNumber,
                artistName: data.artistName,
                title: data.title,
                amazon: data.amazon,
                amazonOriginal: data.amazonOriginal,
                apple: data.apple,
                appleOriginal: data.appleOriginal,
                tidal: data.tidal,
                tidalOriginal: data.tidalOriginal,
                spotify: data.spotify,
                spotifyOriginal: data.spotifyOriginal,
                deezer: data.deezer,
                bandcamp: data.bandcamp,
                youTube: data.youTube,
                vimeo: data.vimeo,
                other: data.other
            });

            return (data.errorString === "");
        })
        .catch((error) => {
            console.log("In Error : " + error);
            return false;
        });

        this.setState({isLoading: false});

        return return_value;
    }

    userAndEntryView() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3">
                        <span dangerouslySetInnerHTML={this.getTranslation(this.state.txtWelcome)} /><br />
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                        <div className="text-center">
                            <button className="btn btn-link" onClick={() => {this.changeLanguage(1)}}>English</button>
                            <button className="btn btn-link" onClick={() => {this.changeLanguage(2)}}>Español</button>
                            <button className="btn btn-link" onClick={() => {this.changeLanguage(3)}}>Portugués</button>
                        </div>
                        {
                            (this.state.apiServiceError !== "" &&
                                <div className="alert alert-danger">{this.state.apiServiceError}</div>
                            )
                        }
                        <div className="entry-form">
                            <label htmlFor="userID"><b><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtUserID)} /></b></label>
                            <input type="text" name="userID" value={this.state.userID} className="form-control" required onChange={this.handleChange} />
                            {
                                (!this.isUserIDValid() && 
                                    <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtUserIDRequired)} /></div>
                                )
                            }
                            <br />
                            <label htmlFor="entryID"><b><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtEntryID)} /></b></label>
                            <input type="text" name="entryID" value={this.state.entryID} className="form-control" required onChange={this.handleChange} />
                            {
                                (!this.isEntryIDValid() && 
                                    <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtEntryIDIDRequired)} /></div>
                                )
                            }
                            <br />
                            <button onClick={this.retrieveEntryForm} disabled={!this.isUserIDValid() && !this.isEntryIDValid()} className="btn btn-primary w-100">{this.getTranslationString(this.state.txtRetrieveEntry)}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    streamingLinkView() {
        return (
            <div className="row">
                <br />
                <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                    <h5 className="text-center">{this.state.categoryNumber}. {this.state.categoryName}</h5>
                    <div className="text-center">
                        <b><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtUserID)} />:</b> { this.state.userID }<br />
                        <b><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtEntryID)} />:</b> { this.state.entryID }<br />
                        <br />
                        {this.state.title} ({this.state.artistName})<br />
                    </div>
                    <br />
                    {
                        (!this.isVideoCategory() &&
                            <div>
                                <div>
                                    <label htmlFor="amazon"><b>Amazon</b></label>
                                    <input type="text" name="amazon" value={this.state.amazon} onChange={this.handleChange} className="form-control" />
                                    {
                                        (!this.isValidURLForSource(this.state.amazon, 'amazon') &&
                                            <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Amazon")} /></div>
                                        )
                                    }
                                </div>
                                <div>
                                    <label htmlFor="apple"><b>Apple</b></label>
                                    <input type="text" name="apple" value={this.state.apple} onChange={this.handleChange} className="form-control" />
                                    {
                                        (!this.isValidURLForSource(this.state.apple, 'apple') &&
                                            <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Apple")} /></div>
                                        )
                                    }
                                </div>
                                <div>
                                    <label htmlFor="bandcamp"><b>Bandcamp</b></label>
                                    <input type="text" name="bandcamp" value={this.state.bandcamp} onChange={this.handleChange} className="form-control" />
                                    {
                                        (!this.isValidURLForSource(this.state.bandcamp, 'bandcamp') &&
                                            <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Bandcamp")} /></div>
                                        )
                                    }
                                </div>
                                <div>
                                    <label htmlFor="deezer"><b>Deezer</b></label>
                                    <input type="text" name="deezer" value={this.state.deezer} onChange={this.handleChange} className="form-control" />
                                    {
                                        (!this.isValidURLForSource(this.state.deezer, 'deezer') &&
                                            <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Deezer")} /></div>
                                        )
                                    }
                                </div>
                                <div>
                                    <label htmlFor="spotify"><b>Spotify</b></label>
                                    <input type="text" name="spotify" value={this.state.spotify} onChange={this.handleChange} className="form-control" />
                                    {
                                        (!this.isValidURLForSource(this.state.spotify, 'spotify') &&
                                            <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Spotify")} /></div>
                                        )
                                    }
                                </div>
                                <div>
                                    <label htmlFor="tidal"><b>Tidal</b></label>
                                    <input type="text" name="tidal" value={this.state.tidal} onChange={this.handleChange} className="form-control" />
                                    {
                                        (!this.isValidURLForSource(this.state.tidal, 'tidal') &&
                                            <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Tidal")} /></div>
                                        )
                                    }
                                </div>
                                <div>
                                    <label htmlFor="youTube"><b>YouTube</b></label>
                                    <input type="text" name="youTube" value={this.state.youTube} onChange={this.handleChange} className="form-control" />
                                    {
                                        (!this.isValidURLForSource(this.state.youTube, 'youtube') &&
                                            <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "YouTube")} /></div>
                                        )
                                    }
                                </div>

                                {
                                    (this.state.isRemix && 
                                        <div>
                                            <div>
                                                <label htmlFor="amazonOriginal"><b>Amazon Original</b></label>
                                                <input type="text" name="amazonOriginal" value={this.state.amazonOriginal} onChange={this.handleChange} className="form-control" />
                                                {
                                                    (!this.isValidURLForSource(this.state.amazonOriginal, 'amazon') &&
                                                        <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Amazon")} /></div>
                                                    )
                                                }
                                            </div>
                                            <div>
                                                <label htmlFor="appleOriginal"><b>Apple Original</b></label>
                                                <input type="text" name="appleOriginal" value={this.state.appleOriginal} onChange={this.handleChange} className="form-control" />
                                                {
                                                    (!this.isValidURLForSource(this.state.appleOriginal, 'apple') &&
                                                        <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Apple")} /></div>
                                                    )
                                                }
                                            </div>
                                            <div>
                                                <label htmlFor="spotifyOriginal"><b>Spotify Original</b></label>
                                                <input type="text" name="spotifyOriginal" value={this.state.spotifyOriginal} onChange={this.handleChange} className="form-control" />
                                                {
                                                    (!this.isValidURLForSource(this.state.spotifyOriginal, 'spotify') &&
                                                        <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Spotify")} /></div>
                                                    )
                                                }
                                            </div>
                                            <div>
                                                <label htmlFor="tidalOriginal"><b>Tidal Original</b></label>
                                                <input type="text" name="tidalOriginal" value={this.state.tidalOriginal} onChange={this.handleChange} className="form-control" />
                                                {
                                                    (!this.isValidURLForSource(this.state.tidalOriginal, 'tidal') &&
                                                        <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Tidal")} /></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        (this.isVideoCategory() && 
                            <div>
                                <div>
                                    <label htmlFor="vimeo"><b>Vimeo</b></label>
                                    <input type="text" name="vimeo" value={this.state.vimeo} onChange={this.handleChange} className="form-control" />
                                    {
                                        (!this.isValidURLForSource(this.state.vimeo, 'vimeo') &&
                                            <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "Vimeo")} /></div>
                                        )
                                    }
                                </div>
                                <div>
                                    <label htmlFor="youTube"><b>YouTube</b></label>
                                    <input type="text" name="youTube" value={this.state.youTube} onChange={this.handleChange} className="form-control" />
                                    {
                                        (!this.isValidURLForSource(this.state.youTube, 'youtube') &&
                                            <div className="red-text"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtNotValidURL, "YouTube")} /></div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }



                    <br />
                    {
                        (this.state.apiServiceError !== "" &&
                            <div className="alert alert-danger">{this.state.apiServiceError}</div>
                        )
                    }
                    {
                        (this.state.streamingSubmissionCompleted &&
                            <div className="alert alert-success"><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtEntryUpdated)} /></div>
                        )
                    }
                    <button onClick={this.saveEntryForm} className="btn btn-primary" style={{width: "45%"}}>{this.getTranslationString(this.state.txtCompleteSubmission)}</button>
                    <button onClick={this.restartApplication} className="btn btn-secondary" style={{width: "45%", float: "right"}}>{this.getTranslationString(this.state.txtReviewAnotherEntry)}</button>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        )
    }

    chooseEntry() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                        <h5 className="text-center">{this.state.categoryNumber}. {this.state.categoryName}</h5>
                        <div className="text-center">
                            <b><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtUserID)} />:</b> { this.state.userID }<br />
                            <b><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtEntryID)} />:</b> { this.state.entryID }<br />
                            <br />
                            <b><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtSelectEntryToUpdate)} /></b><br />
                        </div>
                    </div>
                </div>
                {this.state.resultsArray.map((current_result) => {
                    return (
                        <div className="row m-2" key={`current_result_${current_result.id}`}>
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                                <button className="btn btn-primary" onClick={() => this.selectEntry({current_result})}><span dangerouslySetInnerHTML={this.getTranslation(this.state.txtSelect)} /></button> {current_result.title} ({current_result.artistName})
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        if (this.state.translations.length === 0)
            return null;

        return (
            <div>
                <div className="row">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 mx-auto text-center">
                        <br />
                        <img src="/Images/LatinRecordingAcademy.svg" className="img-fluid" alt="Latin Recording Academy" /><br />
                        <br />
                        <br />
                    </div>
                </div>
                {
                    (this.state.pageNumber === 1 &&
                        this.userAndEntryView()
                    )
                }
                {
                    (this.state.pageNumber === 2 && 
                        this.chooseEntry()
                    )
                }
                {
                    (this.state.pageNumber === 3 &&
                        this.streamingLinkView()
                    )
                }
                <br />
                <br />
                <br />
            </div>
        )
    }
}

export default LarasHome;
